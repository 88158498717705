<template>
  <ClientOnly>
    <div
      v-click-outside="() => (showList = false)"
      class="select-location"
      :data-test-id="elementTest"
      @click="selectCountry && (showList = !showList)"
    >
      <div class="select-location-selected u-p-r-xs" :data-test-id="elementTest + '-selected'">
        <img v-if="selected" :src="selected?.flag" :data-test-id="elementTest + '-selected-flag'" />
        <div
          v-if="selectCountry"
          :class="`icon-angle-${showList ? 'up' : 'down'} u-bg-dark-blue select-location-selected-icon`"
          :data-test-id="elementTest + '-selected-icon'"
        />
      </div>
      <ul v-if="showList" class="select-location-list" :data-test-id="elementTest + '-list'">
        <li
          v-for="(c, idx) in countries"
          :key="idx"
          class="select-location-list-item"
          :class="{ active: c === selected }"
          :data-test-id="elementTest + '-list-item' + idx"
          @click="select(c)"
        >
          <div class="select-location-list-item-img" :data-test-id="elementTest + '-list-item-img' + idx">
            <img :src="c.flag" width="22" height="14" />
          </div>
          <div class="select-location-list-item-text" :data-test-id="elementTest + '-list-item-text' + idx">
            <p>
              {{ c.name.join('/') }} <span>({{ c.dialingCode }})</span>
            </p>
          </div>
        </li>
      </ul>
    </div>
  </ClientOnly>
</template>

<script setup>
import { Metadata } from 'libphonenumber-js'

const props = defineProps({
  modelValue: { type: Object, default: () => {} },
  selectCountry: { type: Boolean, default: false },
  elementTest: { type: String, default: 'select-location' },
})

const emit = defineEmits(['update:modelValue'])
const selected = ref(null)
const showList = ref(false)
const { countries } = useCountries()

onMounted(() => {
  const { tld } = useCurrentLocale()
  selected.value = props.modelValue || countries?.find(c => c.code?.toUpperCase() === tld)
})

const select = country => {
  selected.value = country

  const metadata = new Metadata()
  metadata.selectNumberingPlan(country.code.toUpperCase())
  country.phoneMaxLength = (Math.max(...metadata.numberingPlan.possibleLengths()) + 1).toString()

  emit('update:modelValue', country)
  showList.value = false
}
</script>

<style lang="scss">
.select-location {
  position: relative;
  width: fit-content;
  height: 100%;
  background-color: var(--white);

  &-selected {
    display: flex;
    align-items: center;
    position: relative;
    top: 50%;
    transform: translateY(-50%);

    img {
      width: 28px;
      height: 28px;
      object-fit: contain;
      margin-left: $spacing-xs;
    }

    &:hover {
      cursor: pointer;
    }
  }

  &-list {
    position: absolute;
    padding: $spacing-xs $spacing-sm;
    background-color: var(--white);
    z-index: 2;
    margin-top: 8px;
    border-bottom-left-radius: $spacing-xxs;
    border-bottom-right-radius: $spacing-xxs;
    box-shadow: 0px 5px 30px rgba(5, 23, 47, 0.15);

    &-item {
      display: flex;
      align-items: center;
      gap: $spacing-sm;
      padding: $spacing-xs 0;

      &:hover,
      &.active {
        cursor: pointer;
        .select-location-list-item-text p {
          text-decoration: underline;
        }
      }

      &-img {
        width: 28px;
        height: 28px;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      &-text p {
        display: flex;
        gap: $spacing-xs;
        align-items: center;
        color: var(--steel-grey) !important;
        font-size: pxToRem(12px);
        font-weight: 500;
        line-height: 1;
      }
    }
  }
}
</style>
