<template>
  <div :data-test-id="elementTest">
    <div class="input-phone-label" :data-test-id="elementTest + '-label'">
      <p
        v-if="phoneLabel && (rawInputValue !== '' || fieldFocus)"
        :class="{ 'u-c-primary': greenTitleWhenCorrect && correctInputValue }"
        :data-test-id="elementTest + '-text'"
      >
        {{ $t('fields.phone') }}
      </p>
    </div>
    <div
      class="input-phone"
      :class="[classVariant, { 'u-m-b-less': reinsuranceMessage }, { reinsurance: reinsuranceMessage }]"
    >
      <ClientOnly>
        <SelectLocation v-model="location" :select-country="selectCountry" :element-test="elementTest + '-select'" />
      </ClientOnly>
      <div class="input-phone-tel">
        <div v-if="location" class="input-phone-tel-dialingcode" :data-test-id="elementTest + '-dialingcode'">
          <p>{{ location.dialingCode }}</p>
        </div>
        <InputField
          v-model="phone"
          input-id="phone"
          input-type="tel"
          :placeholder-value="$t('fields.phone')"
          :class-variant="classVariant || 'bg-grey'"
          :error="error"
          :required="required"
          :input-rules="required ? ['required', 'numeric'] : ['numeric']"
          :input-formatter="phoneFormatter"
          :reinsurance-message="reinsuranceMessage"
          :element-test="elementTest + '-input'"
          @focus="fieldFocus = true"
          @blur="e => manageBlur(e)"
          @change="e => $emit('change', e)"
          @input="captureRawInput"
        />
      </div>
    </div>
    <div
      v-if="reinsuranceMessage"
      class="reinsurance-message"
      :class="{
        'u-p-t-md': rawInputValue !== '' && error !== '',
      }"
    >
      {{ reinsuranceMessage }}
    </div>
  </div>
</template>

<script setup>
import { isValidPhoneNumber, Metadata } from 'libphonenumber-js'

import SelectLocation from '@/components/SelectField/SelectLocation'
import InputField from '@/components/InputField/InputField'

const props = defineProps({
  modelValue: { type: Object, default: () => {} },
  required: { type: Boolean, default: false },
  classVariant: { type: String, default: null },
  phoneLabel: { type: Boolean, default: true },
  selectCountry: { type: Boolean, default: false },
  reinsuranceMessage: { type: String, default: null },
  greenTitleWhenCorrect: { type: Boolean, default: false }, // Green title label when input value is ok
  elementTest: { type: String, default: '' },
})

const emit = defineEmits(['update:modelValue', 'is-phone-invalid', 'change', 'update:model-value', 'is-phone-invalid'])
const location = ref(null)
const error = ref('')
const fieldFocus = ref(false)
const rawInputValue = ref('')

const phone = computed({
  get() {
    return props.modelValue?.phone_local || ''
  },
  set(v) {
    setInput(v)
  },
})
const correctInputValue = computed(() => {
  return phone.value && phone.value !== '' && error.value === ''
})

onMounted(() => {
  const { tld } = useCurrentLocale()
  const { countries } = useCountries()
  location.value = countries?.find(c => c.code?.toUpperCase() === tld)
  const metadata = new Metadata()
  metadata.selectNumberingPlan(tld)
  location.value.phoneMaxLength = (Math.max(...(metadata?.numberingPlan?.possibleLengths() || [])) + 1).toString()
  if (props.required) {
    emit('is-phone-invalid', true)
  }
  nextTick(() => {
    if (props.modelValue) {
      if (props.modelValue?.phone_number?.includes(location.value.dialingCode)) {
        const phone = props.modelValue.phone_number.replace(location.value.dialingCode, '0')
        setInput(phone)
      } else if (props.modelValue?.phone_number) {
        setInput(props.modelValue.phone_number)
      } else {
        setInput(props.modelValue)
      }
    }
  })
})

const manageBlur = () => {
  fieldFocus.value = false
}

const setInput = e => {
  const { tld } = useCurrentLocale()
  const { $i18n } = useNuxtApp()

  if (!e) {
    error.value = $i18n.t('rules.required')
    emit('is-phone-invalid', true)
  }
  if (e && !isValidPhoneNumber(e, tld)) {
    error.value = $i18n.t('subscribe.errors.phoneNumber')
    emit('is-phone-invalid', true)
  } else {
    error.value = ''
    let phoneInput = String(e)
    if (phoneInput[0] === '0') {
      phoneInput = phoneInput.substring(1, phoneInput.length)
    }
    const phone = {
      phone_number: location.value?.dialingCode + phoneInput,
      phone_prefix: location.value?.dialingCode.substring(1),
      phone_local: e,
    }
    emit('is-phone-invalid', false)
    emit('update:modelValue', phone)
  }
}

const phoneFormatter = e => {
  if (e) {
    return e.replaceAll(/\s+/g, '').substring(0, location.value?.phoneMaxLength)
  }
  return e
}

const captureRawInput = event => {
  rawInputValue.value = event.target.value
}
</script>

<style lang="scss">
.input-phone-label {
  display: flex;
  align-items: center;
  height: 28px;
  p {
    font-size: pxToRem(12px);
    color: var(--steel-grey);
    font-weight: 500;
    padding-left: $spacing-sm;
  }
}

.input-phone {
  position: relative;
  display: flex;
  align-items: center;
  gap: $spacing-sm;
  background-color: var(--bg-grey);
  padding: $spacing-xxxs;
  height: 40px;
  margin-bottom: $spacing-xxxl;

  .sp-input-field .error,
  .reinsurance-message {
    float: inherit;
    margin-bottom: 0;
    left: 0;
  }

  .sp-input-error {
    position: absolute;
  }

  .sp-input-field {
    margin-bottom: 0;
  }

  &-tel {
    display: flex;
    align-items: center;

    &-dialingcode {
      p {
        color: var(--steel-grey) !important;
        font-size: pxToRem(12px);
        font-weight: 500;
        line-height: 1;
      }
    }

    .sp-input {
      padding-left: $spacing-xs;
      padding-bottom: $spacing-xxxs;
    }
  }
}

.reinsurance-message {
  color: var(--steel-grey);
  font-size: pxToRem(13px);
  padding: 3px $spacing-sm 0 $spacing-sm;
}
</style>
